import * as React from "react"
import { Helmet } from "react-helmet";
import {Button, Navbar, Nav,Form, Container,FormControl,Row,Col } from 'react-bootstrap';
import Select from 'react-select';
import ScrollAnimation from "react-animate-on-scroll"
import { Link }  from "gatsby"
import Layout from "../../../components/layout";
import { SmallSearch, PlusDark, GridView, Sort, Map, Bedroom, Bathroom, Reception, Kitchen } from '@components/icon/icon'
import PopularSearchDynamic from "../../../components/popular-search-dynamic";

// 
import $ from "jquery"
import qs from "qs"
import algoliasearch from "algoliasearch/lite"
import {
    connectStats,
    SortBy,
    connectInfiniteHits,
    InstantSearch,
    RefinementList,
    connectSearchBox,
    connectMenu,
    connectRange,
    Configure,
    connectSortBy,
    ToggleRefinement
} from "react-instantsearch-dom"
import {
  GoogleMapsLoader,
  GeoSearch,
  Marker,
  CustomMarker
} from 'react-instantsearch-dom-maps'
import Cookies from 'universal-cookie'
import { navigate } from "@reach/router"
import {
  parseSearchUrl,
  propertyH1
} from "@components/property-search/utils";
import SeoResults from "@components/property-search/seoResults"
import "../assets/styles/_index.scss"
//

// 
import propertyImg03 from "../../../images/property1.jpg"
// 
export const toTitleCase = (text) => {
  let _text = text || '';
  // console.log('_text : ' + _text);
  if ( _text ) {
    _text = _text.trim().split(' ');
    // console.log('_text.length : ' + _text.length);
    if ( _text.length > 0 ) {
      _text = _text.map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
    }
  }
  return _text;
}
const mycookies = new Cookies();
const updateAfter = 500

var minpriceval = minpriceval || ""
var maxpriceval = maxpriceval || ""
var minbedroomval = minbedroomval || ""
var maxbedroomval = maxbedroomval || ""

// const searchClient = algoliasearch(
//   "CC9EIO92RT",
//   "f25aaa9851bafb4e1f9e3f820b06cb4d"
// )
const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_API_KEY);

const index_name = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}`
  

// 
const styles = {
  control: styles => ({ 
      ...styles, 
      backgroundColor: null, 
      border: 0,
      paddingLeft: 0,
      paddingBottom: '1.1rem',
      borderBottom: '1px solid #000',
      borderRadius: 0,
      outline: 0,
      // fontSize: '1.8rem', //: '2.2rem',
      boxShadow: 'none',
      color: '#4A4A4A',
  }),
  valueContainer: (styles) => ({
      ...styles,
      // fontSize: () => isMobile ? '1.8rem' : '2.2rem',
      paddingLeft: 0
  }),
  dropdownIndicator: (styles) => ({
      ...styles,
      color: "#5D9272"
  }),
  indicatorsContainer: (styles) => ({
      ...styles,
      color: '#5D9272',
  }),
  indicatorSeparator: () => null,
  placeholder: (styles) => ({
      ...styles,
      marginLeft: 0
  })
}

const DownIndicator = () => (
  <div className="down-indicator">
      <PlusDark />
  </div>
)
// 

// Property Results count
const Stats = ({ processingTimeMS, nbHits }) => <>{nbHits}</>

const CustomStats = connectStats(Stats)
// Property Results count

// Searchbox
const SearchBox = connectSearchBox(({ currentRefinement, refine}) => {
  return (
      <div className="field-row">
          <SmallSearch />
          <input 
              type="text" 
              name="search"
              className="search-field"
              value={currentRefinement.replace(/-/g, ' ')}
              placeholder="Street, area or postcode" 
              onChange={event => refine(event.currentTarget.value.replace(/ /g, "-").toLowerCase())}
          />
          {/* <span className="d-none d-md-block">+ 2 Miles</span> */}
      </div>
  )
})
// Searchbox

// Min Price
const priceminRange = [
  {
    value: "0",
    label: "No preference"
  },
  {
    value: "100000",
    label: "£100,000"
  },
  {
    value: "150000",
    label: "£150,000"
  },
  {
    value: "200000",
    label: "£200,000"
  },
  {
    value: "250000",
    label: "£250,000"
  },
  {
    value: "300000",
    label: "£300,000"
  },
  {
    value: "500000",
    label: "£500,000"
  },
  {
    value: "750000",
    label: "£750,000"
  },
  {
    value: "1000000",
    label: "£1,000,000"
  },
  {
    value: "1500000",
    label: "£1,500,000"
  },
  {
    value: "2000000",
    label: "£2,000,000"
  },
  {
    value: "3000000",
    label: "£3,000,000"
  },
  {
    value: "4000000",
    label: "£4,000,000"
  },
  {
    value: "5000000",
    label: "£5,000,000"
  },
  {
    value: "6000000",
    label: "£6,000,000"
  },
  {
    value: "7000000",
    label: "£7,000,000"
  },
  {
    value: "8000000",
    label: "£8,000,000"
  },
  {
    value: "9000000",
    label: "£9,000,000"
  },
  {
    value: "10000000",
    label: "£10,000,000"
  }
]

const minpriceSelect = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {
  let minPriceRange = priceminRange;
  if (currentRefinement.max !== undefined) {
    minPriceRange = priceminRange.filter((x) => parseInt(x.value) < parseInt(currentRefinement.max))
  }
  
  return (
      <Select
          name="price"
          options={minPriceRange}
          defaultValue={currentRefinement.min || ""}
          styles={styles}
          classNamePrefix={"property-dropdown"}
          placeholder="Min Price"
          onChange={(e) => {
              minpriceval = e.value
              if (e.value === "") {
                  if (maxpriceval) {
                      refine({
                          max: maxpriceval,
                      })
                  } else refine(e.value, e.label)
              } else {
                  refine({
                      min: e.value,
                      max: maxpriceval,
                  })
              }
          }}
          components={{ DropdownIndicator: () => <DownIndicator /> }}
      />
  )
}

const CustomminpriceSelect = connectRange(minpriceSelect)
// Min Price

// Property Min Price

// Max Price
const pricemaxRange = [
  {
    value: "0",
    label: "No preference"
  },
  {
    value: "100000",
    label: "£100,000"
  },
  {
    value: "150000",
    label: "£150,000"
  },
  {
    value: "200000",
    label: "£200,000"
  },
  {
    value: "250000",
    label: "£250,000"
  },
  {
    value: "300000",
    label: "£300,000"
  },
  {
    value: "500000",
    label: "£500,000"
  },
  {
    value: "750000",
    label: "£750,000"
  },
  {
    value: "1000000",
    label: "£1,000,000"
  },
  {
    value: "1500000",
    label: "£1,500,000"
  },
  {
    value: "2000000",
    label: "£2,000,000"
  },
  {
    value: "3000000",
    label: "£3,000,000"
  },
  {
    value: "4000000",
    label: "£4,000,000"
  },
  {
    value: "5000000",
    label: "£5,000,000"
  },
  {
    value: "6000000",
    label: "£6,000,000"
  },
  {
    value: "7000000",
    label: "£7,000,000"
  },
  {
    value: "8000000",
    label: "£8,000,000"
  },
  {
    value: "9000000",
    label: "£9,000,000"
  },
  {
    value: "10000000",
    label: "£10,000,000"
  }
]

const maxpriceSelect = ({
    currentRefinement,
    min,
    max,
    precision,
    refine,
}) => {

    let maxPriceRange = pricemaxRange;
    let maxpricedisable = ''
    if (currentRefinement.min !== undefined) {
      maxPriceRange = pricemaxRange.filter((x) => parseInt(x.value) > parseInt(currentRefinement.min))
    }
    if (currentRefinement.min == "10000000") {
      maxpricedisable = true
    }

    return(
        <Select
            name="price"
            options={maxPriceRange}
            defaultValue={currentRefinement.max || ""}
            styles={styles}
            classNamePrefix={"property-dropdown"}
            placeholder="Max Price"
            onChange={(e) => {
                maxpriceval = e.value
                if (e.value === "") {
                    if (minpriceval) {
                        refine({
                            min: minpriceval,
                        })
                    } else refine(e.value, e.label)
                } else {
                    refine({
                        min: minpriceval,
                        max: e.value,
                    })
                }
            }}
            components={{ DropdownIndicator: () => <DownIndicator /> }}
        />
    )
}

const CustommaxpriceSelect = connectRange(maxpriceSelect)
// Property Max Price


// Bedrooms
const bedroomSelect = ({
  currentRefinement,
  min,
  max,
  precision,
  refine,
}) => {

  let bedroomvalselect = ""
  if (currentRefinement.max !== undefined) {
    bedroomvalselect = 0
  } else if (currentRefinement.min !== undefined) {
    bedroomvalselect = currentRefinement.min
  }

  return(
      <Select
          name="price"
          options={[
              { value: "", label: "Bedrooms" },
              { value: '1', label: '1' },
              { value: '2', label: '2' },
              { value: '3', label: '3' },
              { value: '4', label: '4' },
              { value: '5', label: '5' },
              { value: '6', label: '6' }
          ]}
          styles={styles}
          classNamePrefix={"property-dropdown"}
          placeholder="Bedrooms"
          onChange={(e) => {
            if (e.value === "") {
                refine(e.value, e.label)
            } else if (e.value === "0") {
                refine({
                    max: e.value,
                })
            } else {
                refine({
                    min: e.value,
                })
            }
          }}
          components={{ DropdownIndicator: () => <DownIndicator /> }}
      />
  )
}

const CustombedroomSelect = connectRange(bedroomSelect)

// Property type
const buildingSelectbox = ({ items, currentRefinement, refine }) => (
  <Select
    name="building"
    options={[
      { value: "", label: "All Properties" },
      { value: "Flat", label: "Flat" },
      { value: "House", label: "House" },
      { value: "Maisonette", label: "Maisonette" },
      { value: "property", label: "Property" },
    ]}
    placeholder="Property Type"
    styles={styles}
    classNamePrefix={"property-dropdown"}
    onChange={(e) => {refine(e.value, e.label)}}
    components={{ DropdownIndicator: () => <DownIndicator /> }}
  />
)
  
const CustombuildingSelectbox = connectMenu(buildingSelectbox)
// Property type

// Property Min Bedroom
const bedroomminRange = [
    {
      value: "0",
      label: "Any"
    },
    {
      value: "1",
      label: "1 +"
    },
    {
      value: "2",
      label: "2 +"
    },
    {
      value: "3",
      label: "3 +"
    },
    {
      value: "4",
      label: "4 +"
    },
    {
      value: "5",
      label: "5 +"
    },
    {
      value: "6",
      label: "6 +"
    },
    {
      value: "7",
      label: "7 +"
    },
    {
      value: "8",
      label: "8 +"
    },
    {
      value: "9",
      label: "9 +"
    },
    {
      value: "10",
      label: "10 +"
    },
    {
      value: "11",
      label: "11 +"
    },
    {
      value: "12",
      label: "12 +"
    },
    {
      value: "13",
      label: "13 +"
    },
    {
      value: "14",
      label: "14 +"
    },
    {
      value: "15",
      label: "15 +"
    },
    {
      value: "16",
      label: "16 +"
    },
    {
      value: "17",
      label: "17 +"
    },
    {
      value: "18",
      label: "18 +"
    },
    {
      value: "19",
      label: "19 +"
    },
    {
      value: "20",
      label: "20 +"
    }
]

const minbedroomSelect = ({
    currentRefinement,
    min,
    max,
    precision,
    refine,
}) => {
    let minBedroomRange = bedroomminRange;
    if (currentRefinement.max !== undefined) {
      minBedroomRange = bedroomminRange.filter((x) => parseInt(x.value) < parseInt(currentRefinement.max))
    }
    
    return (
        <Form.Group>
            <Form.Label>Min Bedrooms</Form.Label>
            <div className="select-group">
                <Select
                    name="bedroom"
                    options={minBedroomRange}
                    defaultValue={currentRefinement.min || ""}
                    classNamePrefix={"select-opt"}
                    placeholder="Min Bedrooms"
                    onChange={(e) => {
                        minbedroomval = e.value
                        if (e.value === "") {
                            if (maxbedroomval) {
                                refine({
                                    max: maxbedroomval,
                                })
                            } else refine(e.value, e.label)
                        } else {
                            refine({
                                min: e.value,
                                max: maxbedroomval,
                            })
                        }
                    }}
                />
            </div>
        </Form.Group>
    )
}

const CustomminbedroomSelect = connectRange(minbedroomSelect)
// Property Min Bedroom

// Property Max Bedroom
const bedroommaxRange = [
  {
    value: "0",
    label: "Any"
  },
  {
    value: "1",
    label: "1 +"
  },
  {
    value: "2",
    label: "2 +"
  },
  {
    value: "3",
    label: "3 +"
  },
  {
    value: "4",
    label: "4 +"
  },
  {
    value: "5",
    label: "5 +"
  },
  {
    value: "6",
    label: "6 +"
  },
  {
    value: "7",
    label: "7 +"
  },
  {
    value: "8",
    label: "8 +"
  },
  {
    value: "9",
    label: "9 +"
  },
  {
    value: "10",
    label: "10 +"
  },
  {
    value: "11",
    label: "11 +"
  },
  {
    value: "12",
    label: "12 +"
  },
  {
    value: "13",
    label: "13 +"
  },
  {
    value: "14",
    label: "14 +"
  },
  {
    value: "15",
    label: "15 +"
  },
  {
    value: "16",
    label: "16 +"
  },
  {
    value: "17",
    label: "17 +"
  },
  {
    value: "18",
    label: "18 +"
  },
  {
    value: "19",
    label: "19 +"
  },
  {
    value: "20",
    label: "20 +"
  }
]

const maxbedroomSelect = ({
    currentRefinement,
    min,
    max,
    precision,
    refine,
}) => {

    let maxBedroomRange = bedroommaxRange;
    let maxbedroomdisable = ''
    if (currentRefinement.min !== undefined) {
      maxBedroomRange = bedroommaxRange.filter((x) => parseInt(x.value) > parseInt(currentRefinement.min))
    }
    if (currentRefinement.min == "20") {
      maxbedroomdisable = "disabled"
    }

    return(
        <Form.Group>
            <Form.Label>Max Bedrooms</Form.Label>
            <div className="select-group">
                <Select
                    name="bedroom"
                    options={maxBedroomRange}
                    defaultValue={currentRefinement.max || ""}
                    classNamePrefix={"select-opt"}
                    placeholder="Max Bedrooms"
                    onChange={(e) => {
                        maxbedroomval = e.value
                        if (e.value === "") {
                            if (minbedroomval) {
                                refine({
                                    min: minbedroomval,
                                })
                            } else refine(e.value, e.label)
                        } else {
                            refine({
                                min: minbedroomval,
                                max: e.value,
                            })
                        }
                    }}
                />
            </div>
        </Form.Group>
    )
}

const CustommaxbedroomSelect = connectRange(maxbedroomSelect)
// Property Max Bedroom

// Map results
const MapResults = (() => {
  return (
    <>
      <div id="map" className="map">
        <GoogleMapsLoader apiKey={process.env.GATSBY_MAPS_API_KEY}>
          {google => <MapResultswrapper google={google} />}
        </GoogleMapsLoader>
      </div>
    </>
  )
})

var InfoWindow;
var onClickMarker;
var html;

const MapResultswrapper = ( (props) => {
  InfoWindow = new props.google.maps.InfoWindow();

  onClickMarker = ({ hit, marker }) => {
    InfoWindow.close();
    if (InfoWindow.getMap()) {
      InfoWindow.close();
    }

    // convert special characters in string
    function htmlDecode(input){
      var e = document.createElement('div');
      e.innerHTML = input;
      return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }
    // property details url structure
    let uriStr = "";

    if(hit.searchType === "sales" ) {
      uriStr = 'property-for-sale/'
    }
    // property details url structure

    // available days
    let currFullDate = new Date();
    let currYear = currFullDate.getFullYear();
    let currMonth = currFullDate.getMonth() +1;
    let currDate = currFullDate.getDate();
    let currDateCal = currYear+"-"+currMonth+"-"+currDate;
    let propertyDate = hit.AvailableFrom;
    let availableFrom = "";
    let date1 = new Date(propertyDate).getTime();
    let date2 = new Date(currDateCal).getTime();

    if(date1 > date2) {
      var timeDiff = Math.abs(date1 -date2);
      // days difference
      var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      availableFrom = diffDays;
    } else { }
    // available days

    // display address
    var propertyAddressFinal = ""

    if (hit.display_address === undefined ) {
      propertyAddressFinal = hit.display_address;
    } else {
      var propertyAddress = hit.display_address;
      var propertySplit = propertyAddress.split(',');

      for(var i = 0; i < propertySplit.length; i++) {
        // Trim the excess whitespace.
        propertySplit[i] = propertySplit[i].replace(/^\s*/, "").replace(/\s*$/, "");
        // Add additional code here, such as:
        propertyAddressFinal = propertySplit[0];
      }
    }
    var FirstIMG = Object.values(hit.images[0])
    html = '<a href="/'+uriStr+hit.slug+'-'+hit.objectID+'/"><div class="result-cards"><div class="contact-card"><figure><div class="map-info-overlay"></div><img src="'+FirstIMG[0]+'" /><span class="card-tag"></span></figure><div class="info"><h6 class="card-title">'+hit.title+' in '+htmlDecode(hit.display_address)+'</h6><span class="date">£'+hit.price.toLocaleString()+'</span></div></div></div></a>';

    InfoWindow.setContent(html);
    InfoWindow.close();
    InfoWindow.open(marker.getMap(), marker);
  };

  return (
    <>
      {/* <Configure
          hitsPerPage={1000}
      /> */}
      <GeoSearch 
        google={props.google}
        enableRefineOnMapMove={false}
        initialZoom={10}
      >
          {({ hits }) => (
              <div>
                  {hits.map(hit => (
                      <>
                        <CustomMarker
                          anchor={{ x: 0, y: -5 }}
                          key={hit.objectID}
                          position={hit._geoloc}
                          hit={hit}
                          className={"icon-property_map_marker map-marker-"+ hit.objectID}
                          onClick={({ marker }) => {
                            onClickMarker({ hit, marker, });
                          }}
                        />
                      </>
                  ))}
              </div>
          )}
      </GeoSearch>
    </>
  )
})
// Map results

// Create URL
export const createURL = (props, state) => {
  let myarea = state.menu

  const isDefaultRoute =
  !state.query &&
  !state.SortBy &&
  state.page === 1 &&
  state.menu &&
  state.range &&
  !state.range.price &&
  !state.range.bedroom

  if (isDefaultRoute) {
    return ""
  }

  var areaPath = "in-london/"
  var bedroomPath = ""
  var pricePath = ""
  var pagePath = ""
  var propertypath = ""
  var sortPath = ""

  if (state.query) {
    if (state.query) {
      areaPath = "in-" + encodeURIComponent(decodeURIComponent(state.query)) + "/"
    }
  }

  const queryParameters = {}

  // Property path
  if (state.menu) {
    if (myarea["building"]) {
        propertypath = "type-" + myarea["building"] + "/"
    }
  }
  // Property path

 // Bedrooms & Price range path
 if (state.range) {
  // Bedrooms
  if (state.range.hasOwnProperty('bedrooms') && state.range.bedrooms.max === 0) {
    bedroomPath = "studio/" //queryParameters.price_max = state.range.price.max;
  }
  if (state.range.bedrooms) {
    if (state.range.bedrooms.min) {
      bedroomPath = state.range.bedrooms.min + "-and-more-bedrooms/" //queryParameters.price_max = state.range.price.max;
    }
  }

  // Price
  if (state.range.price) {
    if (state.range.price.min && state.range.price.max) {
      pricePath =
        "between-" + state.range.price.min + "-and-" + state.range.price.max + '/' //queryParameters.price_min = state.range.price.min;
    } else if (state.range.price.max) {
      pricePath = "under-" + state.range.price.max + '/' //queryParameters.price_max = state.range.price.max;
    } else if (state.range.price.min) {
      pricePath = "over-" + state.range.price.min + '/' //queryParameters.price_max = state.range.price.max;
    }
  }
}
// Bedrooms & Price range path

  // Sort path
  if(state.sortBy) {
    if( state.sortBy === index_name+"_price_asc" ) {
        sortPath = "sortby-price-asc/";
    }
    if( state.sortBy === index_name+"_price_desc" ) {
        sortPath = "sortby-price-desc/";
    }
    if( state.sortBy === index_name+"_most_recent" ) {
      sortPath = "sortby-most-recent/";
    }
  }
  // Sort path

  // Page path
  if (state.page && state.page !== 1) {
    pagePath = `page-${state.page}/`
  }
  // Page path

  const queryString = qs.stringify(queryParameters, {
    addQueryPrefix: true,
    arrayFormat: "repeat",
  })

  let myUrl = `${areaPath}${propertypath}${bedroomPath}${pricePath}${queryString}${pagePath}${sortPath}`

  if ("/" === myUrl.substr(myUrl.length - 1))
  myUrl = myUrl.substr(0, myUrl.length - 1)

  return `/property-map/for-sale/${myUrl}`
}
// Create URL

// URL to searchstate
const searchStateToUrl = (props, searchState) =>
  searchState ? `${createURL(props, searchState)}` : ""

const urlToSearchState = location => {
  if (location) {
    var query = ""
    let pathUri_main = location.pathname.split("/property-map/for-sale")

    var areaVal = ""
    // var bedVal = 11
    var minpriceVal = ""
    var maxpriceVal = ""
    var minbedroomval = ""
    var maxbedroomval = ""
    var pageVal = ""
    var propertyval = ""
    var sortVal = ""
    var sortVal_filt = ""

    if (pathUri_main[1]) {
      // following could be regexp
      let pathUri = pathUri_main[1].split("/")

      //lets loop
      for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for area
        if (typeof pathUri[vi] === "undefined") {
            continue
        }

        // Property type
        if (pathUri[vi].indexOf("type-") >= 0) {
            propertyval = pathUri[vi].replace("type-", "")
        }

        // Area
        if (pathUri[vi].indexOf("in-") >= 0) {
            areaVal = pathUri[vi].replace("in-", "")
        }

        // Price
        if (
            pathUri[vi].indexOf("between-") >= 0 ||
            pathUri[vi].indexOf("over-") >= 0 ||
            pathUri[vi].indexOf("under-") >= 0
        ) {
            let priceFilt1 = pathUri[vi].split("over-")
            if (priceFilt1[1]) {
            minpriceVal = priceFilt1[1]
            }
            let priceFilt2 = pathUri[vi].split("under-")
            if (priceFilt2[1]) {
            maxpriceVal = priceFilt2[1]
            }
            let priceFilt3 = pathUri[vi].split("between-")
            if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].split("-and-")
            minpriceVal = priceFilt4[0]
            maxpriceVal = priceFilt4[1]
            }
        }

        // Bedrooms
        if (pathUri[vi].indexOf("-and-more-") >= 0) {
          minbedroomval = pathUri[vi].replace("-and-more-bedrooms", "")
        }

        if (pathUri[vi].indexOf("upto-") >= 0) {
          maxbedroomval = pathUri[vi].replace("upto-", "")
        }

        if (pathUri[vi].indexOf("-to-") >= 0) {
          let pathTo_1 = pathUri[vi].split("-to-");
          let parthTo_2 = pathTo_1[1].split("-bedrooms");
          
          minbedroomval = pathTo_1[0];
          maxbedroomval = parthTo_2[0];
        }

        // Sort by
        if (pathUri[vi].indexOf("sortby-") >= 0) {
            sortVal_filt = pathUri[vi].replace("sortby-", "")

            if(sortVal_filt === "price-asc") {
              sortVal = index_name+"_price_asc"
            }

            if(sortVal_filt === "price-desc") {
              sortVal = index_name+"_price_desc"
            }

            if(sortVal_filt === "most-recent") {
              sortVal = index_name+"_most_recent"
            }
        }

        // Page
        if (pathUri[vi].indexOf("page") >= 0) {
            pageVal = pathUri[vi].replace("page-", "")
        }
      }
    }
  }

  // Property type
  if (propertyval) {
    query += `&menu[building]=` + propertyval
  }

  // Area
  if (areaVal) {
    areaVal = areaVal.toLowerCase();
    if (areaVal == "london") query += `&query=`
    else query += `&query=` + areaVal
  }

  // Price
  if (minpriceVal) {
    query += `&range[price][min]=` + minpriceVal
  }

  if (maxpriceVal) {
    query += `&range[price][max]=` + maxpriceVal
  }

  // Bedrooms
  if (minbedroomval) {
    query += `&range[bedrooms][min]=` + minbedroomval
  }

  if (maxbedroomval) {
    query += `&range[bedrooms][max]=` + maxbedroomval
  }
  // if (bedVal == 0) {
  //   query += `&range[bedroom][min]=` + 0
  // } else if (bedVal < 10) {
  //   query += `&range[bedroom][min]=` + bedVal
  // }

  // Page
  if (pageVal) {
    query += `&page=${pageVal}`
  }

  // Sort by
  if (sortVal) {
    query += `&sortBy=` + sortVal
  }

  return qs.parse(query)
}
// URL to searchstate

// Active filter onscroll
let lastScrollY = 0
let activeScroll = ""
// Active filter onscroll

class PropertySalesMap extends React.Component {

  constructor(props) {
    super(props);
  }

  // 
  state = {
    searchState: urlToSearchState(this.props.location),
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({ searchState: urlToSearchState(this.props.location) })
    }
  }

  onSearchStateChange = searchState => {
    clearTimeout(this.debouncedSetState)
      this.debouncedSetState = setTimeout(() => {
        navigate(searchStateToUrl(this.props, searchState), searchState)
      }, updateAfter)
    this.setState({ searchState })
  }

  onSearchStateChange2 = (searchState) => {      
    this.setState({ searchState })
  }
  // 

    componentDidMount() {
      $('.popular-searchlist').replaceWith($('.dynamic-popular-search-footer'));

     // Filter active onscroll
     window.addEventListener("scroll", this.handleScroll, true)
     // Filter active onscroll

      // Sold filter
      $( ".filtersearchsold" ).insertAfter( ".defaultsoldfilter" );
      // Sold filter
    }

    // Filter active onscroll
    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll)
    }
  
    handleScroll = () => {
      lastScrollY = window.scrollY

      if (lastScrollY > 200) {
        activeScroll = "active-scroll"
      } else if (lastScrollY > 0) {
        activeScroll = ""
      }
      
      this.setState({ activeScroll })
    }
    // Filter active onscroll

    // Sales & Rent dropdown
    onChangeSellRent = (e) => {
        navigate(`/${e.value}/`)
    }
    // Sales & Rent dropdown

    // Mobile filter results
    mobileFilter = (e) => {
      $(".filter-results-dropdown").toggleClass("active-result")
    }
    // Mobile filter results

    // Map view
    gridView = (e) => {
      navigate(`/property/for-sale/in-london/`)
    }
    // Map View

    render () {
      const searchParams = parseSearchUrl("sales", this.props.location.pathname);
      const h1 = propertyH1(searchParams);
      const areaName = searchParams.areas;
      const pType = searchParams.pType;

      const isPageLoadBg = (typeof this.props.path === "undefined");

      let areaPropertyName;

      if (h1) {
        areaPropertyName = h1.replace('Doha Qatar', 'Doha, Qatar');
      } else {
        areaPropertyName = h1;
      }
      
        return (
            <>
                {!isPageLoadBg &&
                    <SeoResults noindex="noindex" title={h1} searchParams={searchParams} location={this.props.location} />
                }

                <div className={"wrapper"}>
                <Layout>

                    <div className="page-content">
                        <InstantSearch
                            indexName={index_name}
                            searchClient={searchClient}
                            searchState={this.state.searchState}
                            onSearchStateChange={this.onSearchStateChange}
                            createURL={createURL}
                            routing="true"
                        >

                        <Configure
                            hitsPerPage={1000}
                            filters={`publish:true AND searchType:sales`} 
                        />
<div className="filter-form">
<Container>
    <form>
        <Row>
            <div className="d-none">
                {/* <RefinementList
                    attribute="searchType"
                    defaultRefinement={["sales"]}
                /> */}
            </div>
            <Col className="d-none d-md-block">
                {/* <CustomsearchtypeSaleRent attribute="searchType" /> */}
                <Select 
                    options={[
                        { value: 'property-map/for-sale/in-london/', label: 'Buy' },
                        { value: 'property-map/to-rent/in-london/', label: 'Rent' }
                    ]}
                    defaultValue={{ value: 'sales', label: 'Buy' }}
                    styles={styles}
                    placeholder="Sales"
                    onChange={this.onChangeSellRent}
                    components={{ DropdownIndicator: () => <DownIndicator /> }}
                />
            </Col>
            <Col>
                <SearchBox />
            </Col>
            <Col className="d-none d-lg-block">
                <CustombuildingSelectbox attribute="building" />
            </Col>
            <Col className="d-none d-lg-block slct-min-price">
                <CustomminpriceSelect attribute="price" />
            </Col>
            <Col className="d-none d-lg-block slct-max-price">
                <CustommaxpriceSelect attribute="price" />
            </Col>
            <Col className="d-none d-lg-block slct-room">
                <CustombedroomSelect attribute="bedrooms" />
            </Col>
            </Row>
            </form>
</Container>
</div>

                           

                            <div className="properties-list map-view-list">
                              <Container>
                                <Row>
                                  <Col>
                                  <ScrollAnimation animateIn="fadeInUp">
                                      <div className="search-top d-md-flex justify-content-between align-items-center">
                                          <h1>{areaPropertyName} <strong>(<CustomStats />)</strong></h1>                                          
                                                  <div className="map-icon-wrapper gridview">
                                                    <Link to="/property/for-sale/in-london/">
                                                    <GridView/><span>Grid View</span>
                                                    </Link>
                                                    </div>
                                      </div>
                                      </ScrollAnimation>
                                  </Col>
                                </Row>
                                <Row>
                            <Col md="12" lg="7">
                            <p className="intro-text-bot">Explore our collection of properties for sale in {toTitleCase(areaName)} with Anthony Pepe estate agents. For more details about buying a property, contact one of our estate agents in North London and Hertfordshire.</p>
                            </Col>
                            </Row>
                              </Container>
                            </div>
                            <div className="mapresults">
                              <MapResults />
                            </div>
                        </InstantSearch>
                    </div>
                    <section className="dynamic-popular-search-footer">
                      <PopularSearchDynamic searchtype='sales' txt="sample" searchBedroomfield={searchParams.bedrooms} searchPtype={searchParams.type} Searcharea={searchParams} />
                    </section>
                    </Layout>
                </div>
            </>
        )
    }
}

export default PropertySalesMap